(function(){
	"use strict";

	Storage.prototype.setObject = function(key, value) {
		this.setItem(key, JSON.stringify(value));
	};

	Storage.prototype.getObject = function(key) {
		var value = this.getItem(key);
		return value && JSON.parse(value);
	};

		// functions
	var onReady,
		onScroll,
		onAfterScroll,
		onResize,
		initListeners,
		onFocusInputs,
		onBlurInputs,
		// toggleAccordions,
		// toggleTabs,
		toggleTabsMobile,
		toggleMobileMenu,
		toggleSubMenu,
		// loadArticles,
		// validateSearch,
		// onSubscribeNewsletter,
		// remapSVGforMAC,
		initSwiper,
		onSwiperFixNavigation,
		onSwiperFixGlobal,
		loadSource,
		// script,
		initLightcase,
		fixFormLabel,

		// jQuery Selectors
		$window,
		$document,
		$html,
		$body,
		$header,
		$headerFix,
		$main,
		// $accordions,
		// $accordionsTitle,
		// $tabsTitle,
		// $tabsTitleMobile,
		// $tabsContent,
		// $tabsList,
		$btnMobileMenu,
		$headerNav,
		$btnSubMenu,
		// $btnLoadArticles,
		$btnSearch,
		$btnSubscribe,
		// $fieldSearch,

		// strings
		LANG,

		// integer
		// menuTreshold = 1000,
		windowWidth,
		animSpeed = 1,
		page = 2,

		// timers
		scrollTimer,

		// Plugin Swiper
		swiper = [];

	onReady = function(){

		// Storing jQuery selectors
		$window 			= $(window);
		$document 			= $(document);
		$html 				= $("html");
		$body 				= $("body");
		$header 			= $(".header");
		$headerFix 			= $(".header__fix");
		$main 				= $(".main");

		// $accordions 		= $(".accordions");
		// $accordionsTitle 	= $(".accordions__title");
		// $tabsList 			= $(".tabs__list");
		// $tabsTitle 			= $(".tabs__title");
		// $tabsTitleMobile 	= $(".tabs__title--mobile");
		// $tabsContent 		= $(".tabs__content");
		$btnMobileMenu 		= $(".hamburger");
		$headerNav 			= $(".header--nav");
		$btnSubMenu 		= $(".menu-item-has-children").children('a');
		// $btnLoadArticles 	= $(".btn-load-articles");
		// $btnSearch 			= $(".search-submit");
		// $fieldSearch 		= $(".search-field");
		// $btnSubscribe 		= $(".btn--subscribe");

		LANG = $html.attr('lang').indexOf('fr') >= 0 ? 'fr' : 'en';
		windowWidth = parseFloat($window.width());

		initListeners();
		fixFormLabel();

		/*
		if(navigator.platform.indexOf('Mac') > -1 || $html.hasClass('iOS')){
			remapSVGforMAC();
		}
		*/
		onScroll();
	}

	loadSource = function(url){
		var ext = url.substr( parseInt(url.lastIndexOf(".")) + 1 , url.length );
		var tag;


		switch(ext){
			case 'js':
				tag = 'script';
				break;
			case 'css':
				tag = 'link';
				break;
			case 'png':
			case 'jpg':
			case 'jpeg':
			case 'svg':
			case 'gif':
				tag = 'img';
				break;
		}
		// console.log(ext, tag, url);

		return new Promise(function(resolve, reject){
			/*
			var r = false,
			    t = document.getElementsByTagName("script")[0],
			    s = document.createElement("script");

			s.type = "text/javascript";
			s.src = url;
			s.async = true;
			s.onload = s.onreadystatechange = function () {
			    if (!r && (!this.readyState || this.readyState == "complete")) {
			        r = true;
			        resolve(this);
			    }
			};
			s.onerror = s.onabort = reject;
			t.parentNode.insertBefore(s, t);
			*/
			var element = document.createElement(tag);
			var parent = 'body';
			var attr = 'src';

			// Important success and error for the promise
			element.onload = function() {
			  resolve(url);
			};
			element.onerror = function() {
			  reject(url);
			};

			// Need to set different attributes depending on tag type
			switch(tag) {
			  case 'script':
			    element.async = true;
			    break;
			  case 'link':
			    element.type = 'text/css';
			    element.rel = 'stylesheet';
			    attr = 'href';
			    parent = 'head';
			}

			// Inject into document to kick off loading
			element[attr] = url;
			document[parent].appendChild(element);
		});
	};

	initListeners = function(){

		$window.resize( onResize );
		$window.scroll( onScroll );

		// $accordionsTitle.click( toggleAccordions );
		// $tabsTitle.click( toggleTabs );
		// $tabsTitleMobile.click( toggleTabsMobile );
		$btnMobileMenu.click( toggleMobileMenu );
		$btnSubMenu.click( toggleSubMenu );

		$("input,textarea").focus( onFocusInputs );
		$("input,textarea").blur( onBlurInputs );
		// $btnLoadArticles.click( loadArticles );
		// $btnSearch.click( validateSearch );s
		// $btnSubscribe.click( onSubscribeNewsletter );

		// script('/wp-content/themes/drmercier/dist/scripts/gallery.js');
	// testtest = '';

		if( $(".swiper-container").length > 0 || $("a[data-rel^='lightcase:']", ".file-gallery").length > 0 ){
			Promise.all([
				loadSource(window.BASE + '/dist/scripts/gallery.js'),
				loadSource(window.BASE + '/dist/styles/gallery.css')
			]).then(function(data) {
				// console.log('Everything has loaded!');
				initSwiper();
				initLightcase();
			})
			// .catch(function() {
			// 	console.log('Oh no, epic failure!');
			// });
		}

	}
	initSwiper = function(){
		$('.swiper-container').each(function(index, element){
		    var $el = $(this);

		    swiper[index] = $el.swiper({
				slideClass: 'gallery-item',
				slideActiveClass: 'gallery-item--active',
				wrapperClass: 'gallery',
				nextButton: '.gallery-next-item',
				prevButton: '.gallery-prev-item',
			    slidesPerView: 1,
			    spaceBetween: 10,
			    autoHeight: true,
			    onInit: onSwiperFixNavigation,
			    onSlideChangeStart: onSwiperFixNavigation
			});
		});
	};

	fixFormLabel = function(){
		if( $(".wpcf7-form").length > 0 ){
			$("input,textarea", ".wpcf7-form").each(function(){
				var $this = $(this),
					$parent,
					$label;

				// Add class to label to position it properly
				if( $this.val() !== '' ){
					$parent = $this.parentsUntil('.form__group').parent();
					$label = $parent.children('.form__label');
					$label.addClass('form__label--filled');
				}
			})
		}
	}

	onSwiperFixNavigation = function(swiper){
		var activeIndex = swiper.activeIndex;
		var $container = swiper.container;
		var arrowHeight = 22;

		var height = parseInt($container.find('.gallery-item').eq(activeIndex).children('.gallery-caption').height());
		// console.log(height);
		// console.log($container.find('.gallery-next-item,.gallery-prev-item'));
		$container.find('.gallery-next-item,.gallery-prev-item').css({
			'-webkit-transform':'translateY(-'+(height-arrowHeight) / 2+'px)',
			'transform':'translateY(-'+(height-arrowHeight) / 2+'px)'
		});
	};

	onSwiperFixGlobal = function(){
		for( var i=0; i<swiper.length; i++ ){
			var activeIndex = swiper[i].activeIndex;
			var $container = swiper[i].container;
			var arrowHeight = 22;

			var height = parseInt($container.find('.gallery-item').eq(activeIndex).children('.gallery-caption').height());
			// console.log(height);
			// console.log($container.find('.gallery-next-item,.gallery-prev-item'));
			$container.find('.gallery-next-item,.gallery-prev-item').css({
				'-webkit-transform':'translateY(-'+(height-arrowHeight) / 2+'px)',
				'transform':'translateY(-'+(height-arrowHeight) / 2+'px)'
			});
		}
	}

	// initSwiper = function(){
	// 	console.log('init swiper');
	// 	swiper = new Swiper('.swiper-container', {
	// 		slideClass: 'gallery-item',
	// 		slideActiveClass: 'gallery-item--active',
	// 		wrapperClass: 'gallery',

	// 	    pagination: '.swiper-pagination',
	// 	    prevButton: '.gallery-prev-item',
	// 	    nextButton: '.gallery-next-item',

	// 	    slidesPerView: 1,
	// 	    paginationClickable: false,
	// 	    spaceBetween: 10,
	// 	    autoHeight: false,
	// 	    onInit: onSwiperFixNavigation,
	// 	    onSlideChangeStart: onSwiperFixNavigation
	// 	});
	// }

	// onSwiperFixNavigation = function(swiper){
	// 	var activeIndex = swiper.activeIndex;
	// 	var $container = swiper.container;
	// 	var arrowHeight = 22;

	// 	var height = parseInt($container.find('.gallery-item').eq(activeIndex).children('.gallery-caption').height());
	// 	console.log(height);
	// 	console.log($container.find('.gallery-next-item,.gallery-prev-item'));
	// 	$container.find('.gallery-next-item,.gallery-prev-item').css({
	// 		'-webkit-transform':'translateY(-'+(height-arrowHeight) / 2+'px)',
	// 		'transform':'translateY(-'+(height-arrowHeight) / 2+'px)'
	// 	});
	// }

	initLightcase = function(){
		if( $(".swiper-container").length > 0 ){

			if( !$('.gallery-item a', '.swiper-container').data('rel') || $('.gallery-item a', '.swiper-container').data('rel') === '' ){
				$('.gallery-item a', '.swiper-container').each(function(){
					// console.log($(this).parentsUntil('.swiper-container'));
					$(this).attr('data-rel', 'lightcase:' + $(this).parentsUntil('.gallery').parent().attr('id'));
				});
			}
			$('.gallery-item a', '.swiper-container').lightcase({
				showSequenceInfo: false,
				transition: 'elastic'
			});
		}else{
			$("a[data-rel^='lightcase:']", ".file-gallery").lightcase({
				showSequenceInfo: false,
				transition: 'elastic'
			});
		}
	}

	onResize = function(){
		windowWidth = $(this).width();
	}

	onScroll = function(){
		if (scrollTimer) {
			clearTimeout(scrollTimer);
		}

		scrollTimer = setTimeout(function() {
			onAfterScroll();
		}, 100);
	}

	onFocusInputs = function(e){
		var $this = $(this),
			$parent = $this.parentsUntil('.form__group').parent(),
			$label = $parent.children('.form__label');


		$label.addClass('form__label--filled');
	}

	onBlurInputs = function(e){
		var $this = $(this),
			$parent = $this.parentsUntil('.form__group').parent(),
			$label = $parent.children('.form__label');

		if( $(this).val() === '' ){
			$label.removeClass('form__label--filled');
		}
	}

	onAfterScroll = function(){
		var top = $window.scrollTop();
		// console.log('top: ', top);
		// avoid any logic if nothing must be done
		if ( (top > 0 && $headerFix.hasClass('is-fix')) || (top === 0 && !$headerFix.hasClass('is-fix')) ){
			return;
		}

		if( top > 0 ){
			$headerFix.addClass('is-fix');
			// $headerFix.css('background-color', '#fff');
		}else{
			$headerFix.removeClass('is-fix');
			// $headerFix.css('background-color', '');
		}

	}
/*
	toggleAccordions = function(e){
		e.preventDefault();

		$(this).toggleClass('accordions__title--open').next().slideToggle();
	}

	toggleTabs = function(e){
		e.preventDefault();

		var $this = $(this),
			index = $this.index(),
			$tabsContainer = $this.parentsUntil('.tabs').parent();

		$tabsContainer.children('.tabs__list').children('.tabs__title--open').removeClass('tabs__title--open');
		$(this).addClass('tabs__title--open');

		$tabsContainer.children('.tabs__content').children('.tabs__item--open').removeClass('tabs__item--open');
		$tabsContainer.children('.tabs__content').children('.tabs__item').eq(index).addClass('tabs__item--open');
	}
	toggleTabsMobile = function(e){
		e.preventDefault();

		$(this).toggleClass('tabs__title--mobile--open').next().slideToggle();
	}
*/

	toggleMobileMenu = function(){
		$html.toggleClass("no-scroll");
		$(this).toggleClass('hamburger--open');

		$headerNav.toggleClass('header__nav--open');
		// $headerFix.toggleClass('header__fix--full');
	}

	toggleSubMenu = function(e){
		var $this = $(this);
		if( windowWidth < 1000 ){
			e.preventDefault();
			$this.parent().toggleClass('menu-item-has-children--open');
			$this.next().slideToggle(300);
		}
	}
/*
	validateSearch = function(e){
		if( $fieldSearch.val() === "" ){
			e.preventDefault();
			$fieldSearch.css('border-color', 'red').focus();
		}
	}

	remapSVGforMAC = function(){
		$("svg").each(function(){
			var $this = $(this),
				$svgUse = $this.children('use');
			$svgUse.attr('xlink:href', $svgUse.attr('xlink:href'));

		})
	}
*/
	$(document).ready( onReady );

})();
